// import './bootstrap';
// import '../css/app.css';

// import Alpine from 'alpinejs'
// import collapse from '@alpinejs/collapse'

// Alpine.plugin(collapse)

// window.Alpine = Alpine

// Alpine.start()


// import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
// import collapse from '@alpinejs/collapse'
//
// Alpine.plugin(collapse)
//
// Livewire.start()

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import SplitType from 'split-type'
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


heroTextAnimation();
menuAnimations();


document.addEventListener("livewire:navigated", () => {
    // Add data-scroll-x to body:
    document.body.setAttribute("data-scroll-x", 0);
})



function heroTextAnimation() {
    const text = SplitType.create('#hero')
    const characters = document.querySelectorAll('.char');
    gsap.set('.char', {
        y: '100%',
        force3D: true
    });

// Animation with GSAP
    gsap.to('.char', {
        y: 0,
        opacity: 1,
        stagger: 0.04,
        delay: 0.2,
        duration: 0.3,
        force3D: true
    });
}

function menuAnimations(){

    gsap.to("#dynamicDiv", {
        scrollTrigger: {
            trigger: "#dynamicDiv",
            start: "200px", // When the top of your div hits the top of the viewport
            end: "600px", // End the animation 400px down
            scrub: true, // Smooth scrubbing, true makes it use the default scrubbing speed
            color: "rgb(255, 255, 255)", // Final text color

        },
        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)", // Apply the shadow-md box shadow
        backgroundColor: "rgba(255, 255, 255, 1)", // Final background color (white)
        color: "#170c2d", // Final text color
        ease: "none", // No easing to ensure linear color transition
    });
    gsap.to("#phnumber", {
        scrollTrigger: {
            trigger: "#phnumber",
            start: "200px", // When the top of your div hits the top of the viewport
            end: "600px", // End the animation 400px down
            scrub: true, // Smooth scrubbing, true makes it use the default scrubbing speed

        },
        backgroundColor: "#170c2d", // Final background color (white)
        color: "rgba(255, 255, 255, 1)", // Final text color
        ease: "none", // No easing to ensure linear color transition
    });
    gsap.to("#mobileMenuButton", {
        scrollTrigger: {
            trigger: "#phnumber",
            start: "200px", // When the top of your div hits the top of the viewport
            end: "600px", // End the animation 400px down
            scrub: true, // Smooth scrubbing, true makes it use the default scrubbing speed

        },
        backgroundColor: "#170c2d", // Final background color (white)
        color: "rgba(255, 255, 255, 1)", // Final text color
        ease: "none", // No easing to ensure linear color transition
    });
}







window.scrollHandler = function () {
    return {
        canScrollLeft: false,
        canScrollRight: true,
        activeIndex: 0, // New state to track the index of the active/centered item

        init() {
            const self = this; // Reference to the Alpine component for use inside Swiper callbacks

            const swiper = new Swiper('.mySwiper', {
                // Swiper options here
                loop: true,
                // For example, enable pagination
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next', // Selector for the 'Next' arrow
                    prevEl: '.swiper-button-prev', // Selector for the 'Prev' arrow
                },


                // Event handlers
                on: {
                    slideChange: function () {
                        self.activeIndex = this.realIndex; // Update Alpine's activeIndex with Swiper's current index
                    },
                },
            });
        },

        // updateScrollContainerWidth() {
        //     if(document.getElementById('scrollContainer') !== null){
        //         const scrollParent = document.getElementById('scrollParent');
        //         const scrollContainer = document.getElementById('scrollContainer');
        //         const items = scrollContainer.children;
        //
        //         // Set max width for scrollParent if necessary
        //         let maxWidth = window.innerWidth > 600 ? '600' : window.innerWidth - 30;
        //         scrollParent.style.maxWidth = maxWidth + 'px';
        //
        //         // Update scrollContainer width to match scrollParent
        //         scrollContainer.style.width = maxWidth + 'px';
        //
        //         // Update each item's width to match scrollParent
        //         Array.from(items).forEach(item => {
        //             item.style.width = maxWidth + 'px';
        //             item.style.maxWidth = maxWidth + 'px'; // Ensure that items do not exceed the max width
        //         });
        //     }
        //
        // },
        //
        // handleScroll(event) {
        //
        //     const container = event.target;
        //
        //     // Clear the previous timeout if there's any to reset the debounce timer
        //     clearTimeout(this.scrollTimeout);
        //
        //     // Debounce the scroll event to wait for the user to stop scrolling
        //     this.scrollTimeout = setTimeout(() => {
        //
        //         // Determine the active index
        //         this.activeIndex = this.calculateActiveIndex(container);
        //         // console.log(this.activeIndex);
        //
        //         const scrollContainer = document.getElementById('scrollContainer');
        //         const items = scrollContainer.children;
        //
        //         this.canScrollLeft = this.activeIndex > 0;
        //         this.canScrollRight = this.activeIndex + 1 != items.length;
        //
        //
        //
        //         // Apply styles based on the active index
        //         this.applyStylesToActiveItem(container, this.activeIndex);
        //
        //     }, 150); // 150ms after the user stops scrolling
        // },
        //
        // calculateActiveIndex(container) {
        //     const childrenArray = Array.from(container.children);
        //     // Calculate the midpoint of the container relative to the document
        //     const containerRect = container.getBoundingClientRect();
        //     const containerMidPoint = containerRect.left + containerRect.width / 2;
        //     let activeIndex = 0;
        //     let minDistanceToCenter = Infinity;
        //
        //     childrenArray.forEach((child, index) => {
        //         // Calculate the midpoint of each child relative to the document
        //         const childRect = child.getBoundingClientRect();
        //         const childMidPoint = childRect.left + childRect.width / 2;
        //         let distanceToCenter = Math.abs(containerMidPoint - childMidPoint);
        //
        //         // Update active index if this child is closer to the center
        //         if (distanceToCenter < minDistanceToCenter) {
        //             minDistanceToCenter = distanceToCenter;
        //             activeIndex = index;
        //         }
        //     });
        //
        //     return activeIndex;
        // },
        //
        // applyStylesToActiveItem(container, activeIndex) {
        //     const childrenArray = Array.from(container.children);
        //
        //     childrenArray.forEach((child, index) => {
        //         if (index === activeIndex) {
        //             child.style.opacity = '1';
        //         } else {
        //             child.style.opacity = '0.3';
        //         }
        //     });
        // },
        //
        //
        // scrollTo(direction) {
        //     const container = this.$refs.scrollContainer;
        //     const childrenArray = Array.from(container.children);
        //     let childWidth = childrenArray[0].offsetWidth;
        //     let scrollJump = childWidth + (parseInt(getComputedStyle(childrenArray[0]).marginRight) || 0);
        //
        //     if (direction === 'right') {
        //         if(this.canScrollRight){
        //             container.scrollTo({
        //                 left: container.scrollLeft + scrollJump,
        //                 behavior: 'smooth'
        //             });
        //         }
        //     } else {
        //         if(this.canScrollLeft){
        //             container.scrollTo({
        //                 left: container.scrollLeft - scrollJump,
        //                 behavior: 'smooth'
        //             });
        //         }
        //     }
        //
        //     // A small delay to ensure the scroll has finished before checking the active item
        //     this.handleScroll({target: this.$refs.scrollContainer});
        // },
    };
}


document.addEventListener('DOMContentLoaded', function () {
    let menublock = window.document.getElementById('mainMenu');
    let homepageHeroBlock = window.document.getElementsByClassName('homepage-hero-margin');

    //set HomepageHero block Margin
    for (let i = 0; i < homepageHeroBlock.length; i++) {
        homepageHeroBlock[i].style.marginTop = (menublock.offsetHeight) + "px";
    }

});
document.addEventListener('livewire:navigated', function () {

    window.scrollTo({ top: 0, left: 0, behavior: 'instant' })

       heroTextAnimation();
       menuAnimations();

    gallerySlider.init();


    let menublock = window.document.getElementById('mainMenu');
    let homepageHeroBlock = window.document.getElementsByClassName('homepage-hero-margin');

    //set HomepageHero block Margin
    for (let i = 0; i < homepageHeroBlock.length; i++) {
        homepageHeroBlock[i].style.marginTop = (menublock.offsetHeight) + "px";
    }


});

const gallerySlider = (function () {
    const init = function () {
        createObserver();
    };

    const createObserver = function () {
        let options = {
            root: null,
            rootMargin: "-80px",
            threshold: 0.25
        };

        let observer = new IntersectionObserver(
            function (entries, observer) {
                handleIntersect(entries, observer);
            },
            options);

        let targetElements = document.querySelectorAll(".gallery");

        targetElements.forEach((targetElement) => {
            observer.observe(targetElement);
        });
    };

    const handleIntersect = function (entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                console.log(entry.target);
                entry.target.classList.add("slide-in-right");
                observer.unobserve(entry.target);
            }
        });
    };

    return {
        init: init
    };
})();

gallerySlider.init();
